/*jshint node: true, jquery: true */

"use strict";

/**
 * Forms
 */

// jQuery DOM Manipulation Library
// You must use this syntax to prevent issues, especially w/ Bootstrap.
window.$ = window.jQuery = require('jquery');
require('better-dom');
require('better-form-validation');
require('bootstrap-multiselect');

// training registration form
$(function() {
	var form = $('.training-registration');
	form.find('.training-event-note').addClass('hidden');

	form.find('[name=training-event]').on('change', function()
	{
		form.find('.training-event-note').addClass('hidden');
		form.find('#training-event-' + this.value + '-note').removeClass('hidden');
	});

	form.find('#projects').multiselect({
		enableClickableOptGroups: true
	});
});


// Contact Form
$(function() {

    var form = $('.contact-form');

    // Toggle form groups when switching between general and support
    form.find('#field-question,#field-support,#field-none').on('change', function(e)
    {
        var option = $(e.currentTarget);

        // Show the general fields
        if( option.is('#field-question') )
        {
            form.find('.contact-form-support').addClass('hidden');
            form.find('.contact-form-general').removeClass('hidden');
        }

        // Show the support fields
        else if( option.is('#field-support') )
        {
            form.find('.contact-form-general').addClass('hidden');
            form.find('.contact-form-support').removeClass('hidden');
        }
		else
		{
            form.find('.contact-form-general').addClass('hidden');
            form.find('.contact-form-support').addClass('hidden');
		}
    });

    // Set #field-question to true, trigger the form on page load
    form.find('#field-none').prop( "checked", true ).trigger('change');

});

// Register Form
$(function() {

    var form = $('.register-form');

	$(function () {
		$('[data-toggle="tooltip"]').tooltip({
			placement: "right auto",
		});
	})


    // Make "Add contact" button clone a new staff member
    var members = $('.staff-members');
    var addButton = $('.staff-member-add');
    var removeButton = $('.staff-member-remove');
    addButton.on('click', function()
    {
        // Create the clone
        var clone = members.find('.staff-member:first').clone();
        var membersCount = members.find('.staff-member').length;
        var cloneNumber = membersCount + 1;
        var fields = [
            'contact-1-email',
            'contact-1-fax',
            'contact-1-name',
            'contact-1-phone',
            'contact-1-position',
            'contact-1-general-point-of-contact',
			'contact-1-query-contact',
			'contact-1-shipping-contact',
			'contact-1-linebreak'
        ];
        for( var i in fields )
        {
            var field = fields[i];
            var name = field.replace('1', cloneNumber);
            clone.find('label[for=' + field + ']').attr('for', name);
            clone.find('#' + field).attr('id', name).attr('name', name);
			clone.find('input[type="text"]').val('');
			clone.find('input[type="email"]').val('');
			clone.find('input[type="tel"]').val('');
			clone.find('input[type="checkbox"]').attr('checked', false);
        }
        clone.find('.staff-member-count').text( cloneNumber );
        members.append(clone);

        // Show the remove button when there's more than 1 on the page
        if( cloneNumber > 1 )
        {
            removeButton.removeClass('hidden');
        }
    });

    // Make "Remove contact" button remove the last staff member
    removeButton.on('click', function()
    {
        members.find('.staff-member:last').remove();

        // Hide the remove button when there's less than 2 on the page
        var membersCount = members.find('.staff-member').length;
        if( membersCount <= 1 )
        {
            removeButton.addClass('hidden');
        }
    });

});

// Verify reCAPTCHA checkbox is checked.
$(function() {
	if (typeof grecaptcha === "undefined") {
		return;
	}
	
	$('.submit-with-captcha').on('click',function(e){                
	
		var indexAttr = e.target.getAttribute('recaptcha-index');
		var index = indexAttr == null ? 0 : parseInt(indexAttr);
		
		if (grecaptcha.getResponse(index) == ""){
			e.preventDefault();
			$('.recaptcha-error').show();
		}
		else {
			$('.recaptcha-error').hide();
		}
	});
});

DOM.extend("body", function() {

	// Phone number input validation
	// Make sure that if "contact me by phone" is
	// checked, the phone input is not empty
	var phoneInput = DOM.find('[name=phone-number]');

	if (!phoneInput.validity) {
		return;
	}
	
	phoneInput.validity(function() {
		var input = $('input[name=contact-by-phone]');
		if( input.is(':checked') && phoneInput.get() == ''  ){
			return "If you wish to be contacted by phone, please provide a phone number";
		}
	});
		
});
