/*jshint node: true, jquery: true */

"use strict";

/**
 * Main Application Scripts
 */

// Enable JS if available
require('./_modules/_accessibility');

// jQuery DOM Manipulation Library
// You must use this syntax to prevent issues, especially w/ Bootstrap.
window.$ = window.jQuery = require('jquery');

// Google Webfont Loader (optional)
// require('./_modules/_webfont');

// Twitter Bootstrap Optimized Build
require('./_modules/_bootstrap');

// IE/Mobile Platform Detection
require('./_modules/_ie10');
// require('./_modules/_mobile'); // (optional)

// Load Javascript Custom Forms (optional)
// Note: JCF allows customization of form elements that aren't CSS style-able,
// but it is clunky and very big. We advise against using it, it is not
// required for LDMS.
// require('./_modules/_jcf');

// Load polyfills
//require('matchmedia');
//require('picturefill');

// Load Your Custom Modules
require('./_modules/_forms');

// used for searching. It's big, remove this if search is not implemented
// require('./_modules/_search');
