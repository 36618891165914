/*jshint node: true, jquery: true */

"use strict";

/**
 * Twitter Bootstrap Optimized build
 */

// Require dependencies
window.$ = window.jQuery = require('jquery');

// Enable only the things you need here.
require('bootstrap-transition');
// require('bootstrap-alert');
// require('bootstrap-button');
require('bootstrap-carousel');
require('bootstrap-collapse');
require('bootstrap-dropdown');
// require('bootstrap-modal');
require('bootstrap-offcanvas');
require('bootstrap-tooltip');
require('bootstrap-popover');
// require('bootstrap-scrollspy');
// require('bootstrap-tab');
// require('bootstrap-affix');


// Initialize popovers
$(function () {
  $('[data-toggle="popover"]').popover();
});

