/*jshint node: true */

"use strict";

/**
 * Accessibility Scripts
 */

// Remove .no-js class from body.
// Replace with .js class.
var bodyClass = document.body.className;
bodyClass = bodyClass.replace('no-js', '');
bodyClass += ' js';
document.body.className = bodyClass;
